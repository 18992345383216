import React from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import './App.css';

interface State {
  isExecuting: boolean
  title: string
  error: string
}

interface Props {
  title: string
  onExecute: () => Promise<boolean>
}

class ExecuteButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isExecuting: false,
      title: props.title,
      error: ''
    }
  }

  async execute() {
    try {
      this.setState({isExecuting: true, error: ''})
      await this.props.onExecute()
    }
    catch (error){
      this.setState({error: `${error}`})
    }
    finally {
      this.setState({isExecuting: false})
    }
  }

  render() {
    return (
      <div>
        {this.state.isExecuting ?
          <Spinner animation="border" role="status" />
            :
          <Button variant="outline-primary" onClick={() => this.execute()}>{this.state.title}</Button>
        }

       {this.state.error.length > 0 && <Card.Title>{this.state.error}</Card.Title>}
      </div>
    )
  }
}

export default ExecuteButton;