import React from 'react';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Table from 'react-bootstrap/Table';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import './App.css';

interface State {
  linkName: string,
  referrals: Array<{userId: string, client: string, uniqueId: string, install: boolean, signUp: boolean, monthlySubscription: boolean, annualSubscription: boolean}>
}

class GetReferralInfo extends React.Component<any, State> {
  getReferralsStats = (data: any) => { 
    const res: {data: { linkName: string, installs: Array<any>, signUps: Array<any>, subscriptionsMonthly: Array<any>, subscriptionsAnnual: Array<any> }} = {data: { linkName: '', installs: [], signUps: [], subscriptionsMonthly: [], subscriptionsAnnual: [] }};
    
    return res
  }

  constructor(props: {firebase: any}) {
    super(props)

    this.state = {
      linkName: '',
      referrals: []
    }

    const functions = props.firebase.functions();
    this.getReferralsStats = functions.httpsCallable('getReferralsStats');
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Referral information</Card.Title>
          <Card.Text>
            Get your referral information
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Link Name</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({linkName: e.target.value})}
              value={this.state.linkName}
              placeholder="e.g. drivers"
              aria-label="e.g. drivers"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Get'} onExecute={async () => {
            return new Promise(async (resolve, reject) => {
              try {
                  if (this.state.linkName.length === 0) {
                    throw 'please provide link name'
                  }

                  const res: any = (await this.getReferralsStats({linkName: this.state.linkName})).data
                  const data = res.data
                  console.log(`data: ${JSON.stringify(data)}`)
                  // const data = data2.data
                  let dic: { [ind: string]: any } = {}
                  
                  for(let i=0; i < data.installs.length; i++) {
                    const curr: {uniqueId: string, client: string} = data.installs[i]
                    dic[curr.uniqueId] = {userId: undefined, uniqueId: curr.uniqueId, client: curr.client, install: true, signUp: false, monthlySubscription: false, annualSubscription: false}
                  }

                  for(let i=0; i < data.signUps.length; i++) {
                    const curr: {userId: string, uniqueId: string, client: string} = data.signUps[i]
                    if (!(curr.uniqueId in dic)) {
                      dic[curr.uniqueId] = {userId: curr.userId, uniqueId: curr.uniqueId, client: curr.client, install: false, signUp: true, monthlySubscription: false, annualSubscription: false}
                    } else {
                      const oldVal = dic[curr.uniqueId]
                      if (oldVal != null) {
                        dic[curr.uniqueId] = {userId: curr.userId, uniqueId: curr.uniqueId, client: oldVal.client, install: oldVal.install, signUp: true, monthlySubscription: oldVal.monthlySubscription, annualSubscription: oldVal.annualSubscription}
                      }
                    }
                  }

                  for(let i=0; i < data.subscriptionsAnnual.length; i++) {
                    const curr: {uniqueId: string, client: string} = data.subscriptionsAnnual[i]
                    if (!(curr.uniqueId in dic)) {
                      dic[curr.uniqueId] = {userId: undefined, uniqueId: curr.uniqueId, client: curr.client, install: false, signUp: false, monthlySubscription: false, annualSubscription: true}
                    } else {
                      const oldVal = dic[curr.uniqueId]
                      if (oldVal != null) {
                        dic[curr.uniqueId] = {userId: oldVal.userId, uniqueId: curr.uniqueId, client: oldVal.client, install: oldVal.install, signUp: oldVal.signUp, monthlySubscription: oldVal.monthlySubscription, annualSubscription: true}
                      }
                    }
                  }

                  for(let i=0; i < data.subscriptionsMonthly.length; i++) {
                    const curr: {uniqueId: string, client: string} = data.subscriptionsMonthly[i]
                    if (!(curr.uniqueId in dic)) {
                      dic[curr.uniqueId] = {userId: undefined, uniqueId: curr.uniqueId, client: curr.client, install: false, signUp: false, monthlySubscription: true, annualSubscription: false}
                    } else {
                      const oldVal = dic[curr.uniqueId]
                      if (oldVal != null) {
                        dic[curr.uniqueId] = {userId: oldVal.userId, uniqueId: curr.uniqueId, client: oldVal.client, install: oldVal.install, signUp: oldVal.signUp, monthlySubscription: true, annualSubscription: oldVal.annualSubscription}
                      }
                    }
                  }

                  const referrals: Array<{userId: string, client: string, uniqueId: string, install: boolean, signUp: boolean, monthlySubscription: boolean, annualSubscription: boolean}> = []

                  for (var key in dic) {
                    const val = dic[key]
                    if (val != null){
                      referrals.push(val)
                    }
                  }

                  console.log(`referrals: ${JSON.stringify(referrals)}`)
                  this.setState({referrals: referrals})

                  resolve(true)
              }
              catch(error) {
                 reject('erorr: make sure you type your link name properly (e.g. https://thisismilo.page.link/drivers -> drivers). Case sensitive.')
              }
            })
          }} />

          {this.state.referrals.length > 0 &&
            <Table striped bordered hover style={{marginTop: '10px'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>id</th>
                  <th>Device</th>
                  <th>Installed</th>
                  <th>Signed Up</th>
                  <th>Subscribed</th>
                </tr>
              </thead>
              <tbody>
                {this.state.referrals.map((referral, i) => (
                  <tr key={referral.uniqueId}>
                    <td>{i}</td>
                    <td>{`${referral.uniqueId}${referral.userId && ` (${referral.userId})`}`}</td>
                    <td>{referral.client}</td>
                    <td>{referral.install ? 'YES' : 'NO'}</td>
                    <td>{referral.signUp ? 'YES' : 'NO'}</td>
                    <td>{referral.annualSubscription ? 'ANNUAL' : referral.monthlySubscription ? 'MONTHLY' : 'NONE'}</td>
                  </tr>
                ))}                
              </tbody>
            </Table>
          }
        </Card.Body>
      </Card>
    )
  }
}

export default GetReferralInfo;