export const Styles = {
  CardStyle: {
    padding: '10px',
    width: '100%',
    backgroundColor: '#F2F2F2',
    borderColor: '#239741',
    borderWidth: 4,
    borderRadius: 4,
    borderStyle: "solid"
  }
}