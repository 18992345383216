import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

import GetReferralInfo from './GetReferralInfo'
import {Styles} from './Styles'
import './App.css';

interface State {
  isSignedIn: boolean
  user: any
}

// Configure Firebase.
const config = {
  apiKey: "AIzaSyB_8rv09zex31NUFLUUs_mnhoJihuhQLFY",
  authDomain: "milenager-9e6c4.firebaseapp.com",
  databaseURL: "https://milenager-9e6c4.firebaseio.com",
  projectId: "milenager-9e6c4",
  storageBucket: "milenager-9e6c4.appspot.com",
  messagingSenderId: "1035703882112",
  appId: "1:1035703882112:web:ede7a4cae0878340"
};
firebase.initializeApp(config);

class App extends React.Component<any, State> {
  uiConfig: any
  unregisterAuthObserver: any

  constructor(props: any) {
    super(props)

    this.state = {
      isSignedIn: false,
      user: null
    }

    // Configure FirebaseUI.
    this.uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {
          this.setState({isSignedIn: user !== null, user: user})
        }
    );
  }
  
  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    return (
      <Container className={"body"}>
        <div style={{flex: 1.0, flexDirection: 'row', justifyContent: 'flex-start'}}>
          <div style={{flex: 1.0, color: 'white', fontSize:80, fontFamily: "Work Sans"}}>MILO<span style={{flex: 1.0, color: '#239741'}}>.</span></div>
        </div>
        {!this.state.user ?
            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/> 
            :
            <Row style={{marginTop: '10px'}}>
              <Col>
                <Card style={Styles.CardStyle}>
                  <Card.Body>
                    <Card.Title>Signed in as: {this.state.user.email}</Card.Title>
                    <Button onClick={() => firebase.auth().signOut()} variant="outline-danger">Sign Out</Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        }
        { this.state.user && 
          <Row style={{marginTop: '10px'}}>
            <Col>
              <GetReferralInfo firebase={firebase}/>
            </Col>
          </Row>
        }
        <div style={{flex: 1.0, color: 'white', fontSize:16, fontFamily: "Work Sans"}}>© Milo 2020. All Rights Reserved.</div>
        <div>
          <a style={{flex: 1.0, fontSize:16, fontFamily: "Work Sans"}} href="http://www.thisismilo.com/privacy">Privacy Policy</a>
        </div>
        <div>
        <a style={{flex: 1.0, fontSize:16, fontFamily: "Work Sans"}} href="https://www.thisismilo.com/terms-and-conditions">Terms and Conditions</a>
        </div>
      </Container>
    )
  }
}

export default App;